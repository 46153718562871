import React, { Component } from "react"
// import Topbar from '../components/topbar'
// import Footer from '../components/footer'

class NotFound extends Component {

  render() {

    return <div>
      <div className="container">
        <h1>Page not found - 404</h1>
      </div>

    </div>
  }
}

export default NotFound
